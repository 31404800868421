import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Committeemembers from "../../components/about/Committeemembers";
import Footer from "../../components/Footer";

const AboutPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | About Committee Members">
            <NavOne />
            <PageHeader title="Committee Members" />
            <Committeemembers />
            <Footer />
        </Layout>
    );
};

export default AboutPage;
