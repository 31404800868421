import React from "react";
import axios from "axios";
import LoadingGif1 from "../../assets/gifs/loading_1.gif";
import JfmContributorItem from "./CommitteemembersCard";

var Api_url = process.env.GATSBY_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
    },
};

const contributors = [];

class Team extends React.Component {

    constructor() {
        super();
        this.state = {
            Records: contributors,
            LoadingFlag: false,
            TotalRecords: 0,
        }
    };

    handleChange(e) {
        // this.setState({ LomId: event.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    getAllRecords = async () => {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/wm/yearwise/team/members/lists`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            YearCode: 68,
            LomCode: 3,
            TeamCode: 4,
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                console.log("result=" + JSON.stringify(result));
                var n1 = result.length;
                console.log("n1=" + n1);
                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }
    render() {
        return (
            <section className="team-area team-area2 text-center">
                <div className="container">
                    <div className="row team-content-wrap">
                        <div className="col-lg-12 text-center">
                            {this.state.LoadingFlag ? <img src={LoadingGif1} alt="" width="300px" /> : ''}
                        </div>
                        {this.state.LoadingFlag ? '' :
                            (this.state.TotalRecords > 0 ?
                                this.state.Records.map(item => (
                                    <div className="col-lg-3 col-sm-6">
                                        <JfmContributorItem blogContent={item} />
                                    </div>
                                ))
                                :
                                'NA'
                            )
                        }
                    </div>
                </div>
            </section>
        );
    }
};

export default Team;
