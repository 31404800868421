import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div className="team-item team-item1">
            <div className="team__img">
                <Link to={props.blogContent.TitleLink}> <img src={props.blogContent.ProfilePic} alt="avatar" /> </Link>
                <div className="team__img-links">
                    <ul>
                        <li>
                            <Link to={props.blogContent.FacebookUrl}> <i className="fa fa-facebook"></i> </Link>
                        </li>
                        <li>
                            <Link to={props.blogContent.InstagramUrl}> <i className="fa fa-instagram"></i> </Link>
                        </li>
                        <li>
                            <Link to={props.blogContent.TwitterUrl}> <i className="fa fa-twitter"></i> </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="team__title">
                <h3 className="team__title-title">
                    <Link to={props.blogContent.TitleLink}>{props.blogContent.MemberName} </Link>
                </h3>
                <span className="team__title-meta">{props.blogContent.DesignationTxt}</span>
            </div>
        </div>
    );
};

export default ShortTitles;
